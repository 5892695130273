@import 'colors';
@import 'mixin';

body,
#root,
.App {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  vertical-align: top;
  font-weight: 300;
  color: $text-color;
  border: 0;
  outline: none;
}
